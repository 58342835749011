import { LikeSvg, UpdatesSvg } from '../../assets/svg-icon';
import React, { FC, useEffect } from 'react';
import logoDark from '../../assets/images/Logo-dark.svg';
import noData from '../../assets/images/no-data.png';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getListPosts, postUpdatesLike } from '../../store/redux-toolkit/updates/updates-thunks';
import { getPostsSelector } from '../../store/redux-toolkit/updates/updates-selector';
import { Post } from '../../store/redux-toolkit/updates/updates-types';
import { Loader } from '../../ui/Loader';
import { getHTMLFromEditorJSONString } from '../../utils/lexicalToHtml';
import parse from 'html-react-parser';
import classNames from 'classnames';
import moment from 'moment';

export const Updates: FC = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(getPostsSelector);

  const onLike = (post: Post) => {
    dispatch(
      postUpdatesLike({
        paramsApi: {
          post_uuid: post.uuid,
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getListPosts());
  }, []);

  const rawContent = 'This is {{bold}}bold{{/bold}} and {{italic}}italic{{/italic}} text.';

  return posts?.show_count ? (
    <div className='container '>
      {posts ? (
        <div className='udpdate-list'>
          {posts?.data?.data?.map((post) => (
            <div key={post.id} className='update-container'>
              <div className='update-header'>
                <div className='update-header__number'>
                  <span className='update-header__number-icon'>
                    <UpdatesSvg />
                  </span>
                  UPDATE #1
                </div>
                <h2 className='update-header__title'>{post.title}</h2>
              </div>
              <div className='author'>
                <div className='author__avatar'>
                  <img loading='lazy' src={logoDark} alt='logoDark' />
                </div>
                <div className='author__info'>
                  <p className='author__name'>
                    {post.creator_name[0].toUpperCase() + post.creator_name.slice(1)}
                  </p>
                  <p className='author__date'>{moment(post.create_time).format('MMMM DD, YYYY')}</p>
                </div>
              </div>
              <div className='update-content'>
                <p>{post.description}</p>
                {post.images[0]?.path ? (
                  <img loading='lazy' src={post.images[0]?.path} alt='img' />
                ) : null}
                {post.full_description && parse(getHTMLFromEditorJSONString(post.full_description))}
                {post.links[0]?.link_url && (
                  <p>
                    {' '}
                    <a href={`https://${post.links[0]?.link_url}`}>{post.links[0]?.description}</a>
                  </p>
                )}
              </div>
              <div className='update-actions'>
                <div className='like-group'>
                  <button
                    onClick={() => onLike(post)}
                    className={classNames('action-btn', { active: post.likes })}
                  >
                    <span className='action-btn__icon'>
                      <LikeSvg />
                    </span>
                    Like
                  </button>
                  {post.likes ? (
                    <p className='like-group__text'>{`${post.likes} people like this update`}</p>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
          {/* <div className='update-container'>
          <div className='update-header'>
            <div className='update-header__number'>
              <span className='update-header__number-icon'>
                <UpdatesSvg />
              </span>
              UPDATE #2
            </div>
            <h2 className='update-header__title'>
              Aliquam facilisi fringilla nisl sed lorem sodales.
            </h2>
          </div>
          <div className='author'>
            <div className='author__avatar'>
              <img loading="lazy" src={logoDark} alt='logoDark' />
            </div>
            <div className='author__info'>
              <p className='author__name'>Author name</p>
              <p className='author__date'>November 16, 2023</p>
            </div>
          </div>
          <div className='update-content'>
            <p>
              Lorem ipsum dolor sit amet consectetur. Nibh diam pellentesque eu viverra eget augue.
              Nunc suspendisse at mattis vel imperdiet scelerisque hendrerit. Non volutpat ut
              tincidunt faucibus vitae dui. Consectetur augue at vel sapien et lorem dictum. Est sed
              hendrerit ac pellentesque ut pellentesque. Neque volutpat arcu quis vulputate sed
              volutpat facilisi donec aliquam.
            </p>
            <p>
              Download: <a href='/'>playch.tv/updatedownload</a>
            </p>
          </div>
          <div className='update-actions'>
            <div className='like-group'>
              <button className='action-btn'>
                <span className='action-btn__icon'>
                  <LikeSvg />
                </span>
                Like
              </button>
              <p className='like-group__text'>3 people like this update</p>
            </div>
          </div>
        </div> */}
        </div>
      ) : (
        <div style={{ height: window.screen.availHeight }}>
          <Loader />
        </div>
      )}
    </div>
  ) : (
    <div className='container'>
      <div className='plug'>
        <img loading='lazy' src={noData} alt='No Data' />
      </div>
    </div>
  );
};
