import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ERoutes } from './enum/types';
import PublicLayout from './layouts/PublicLayout';
import { PublicRoutes } from './routes/PublicRoutes';
import SettingsLayout from './layouts/SettingsLayout/SettingsLayout';
import { SettingsRoutes } from './routes/SettingsRoutes';
import './assets/styles/index.scss';

function App() {
  const [loading, setLoading] = useState(true);

  const onStartApp = () => {
    setLoading(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    setTimeout(onStartApp, 1000);
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <div>
      {loading && (
        <div
          style={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            zIndex: 10000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#171717',
            overflow: 'hidden',
          }}
        >
          <div>
            <svg
              style={{ animation: 'spin 2s linear infinite' }}
              width='70'
              height='70'
              viewBox='0 0 70 70'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M40.3125 60.1984C54.3884 60.1984 65.7992 48.6026 65.7992 34.2984C65.7992 19.9943 54.3884 8.39844 40.3125 8.39844C30.8529 8.39844 22.5969 13.6356 18.1992 21.4125C22.1952 15.94 28.6704 12.3831 35.9788 12.3831C48.1171 12.3831 57.9572 22.1949 57.9572 34.2984C57.9572 46.402 48.1171 56.2138 35.9788 56.2138C28.6704 56.2138 22.1952 52.6569 18.1992 47.1844C22.5969 54.9612 30.8529 60.1984 40.3125 60.1984Z'
                fill='#FF9600'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M35.1714 0C15.7467 0 0 15.67 0 35C0 54.33 15.7467 70 35.1714 70C48.3068 70 59.7603 62.8347 65.8 52.2173C60.3076 59.7804 51.2931 64.713 41.1039 64.713C24.3707 64.713 10.8057 51.41 10.8057 35C10.8057 18.59 24.3707 5.28701 41.1039 5.28701C51.2931 5.28701 60.3076 10.2196 65.8 17.7827C59.7603 7.16534 48.3068 0 35.1714 0Z'
                fill='#2C2C2C'
              />
            </svg>
          </div>
        </div>
      )}

      <Routes>
        <Route path={ERoutes.home} element={<PublicLayout />}>
          {PublicRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path={ERoutes.settings} element={<SettingsLayout />}>
          {SettingsRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element}>
              {route?.children &&
                route.children.map((route) => (
                  <Route key={route.path} path={route.path} element={route.element} />
                ))}
            </Route>
          ))}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
